import {
    Box,
    Button,
    Container,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { blue, green, grey } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from "@material-ui/icons/Warning";
import WifiIcon from "@material-ui/icons/Wifi";
import { Alert } from "@material-ui/lab";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
    ApiCallAddLoanerBadge,
    ApiCallDeleteLoanerBadge,
    ApiCallDeleteRequest,
    ApiCallGetActiveRequests,
    ApiCallGetActiveUser,
    ApiCallGetInactiveLoanerBadges,
    ApiCallGetInactiveRequests,
    ApiCallGetLoanerBadges,
    ApiCallUpdateLoanerBadge,
} from "../Utils/ApiCalls";
import { GetPer } from "../Utils/PersonaPermissions";
import { formatDate } from "../utils";
import LoanerFilter from "./Filter/LoanerFilter";
import RequestFilter from "./Filter/RequestFilter";
import NewLoanerBadge from "./Loaner/NewLoanerBadge";
import ConfirmDialog from "./Misc/ConfirmDialog";
import RequestDialog from "./RequestDialog";

class MainForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingActive: true,

            module: this.props.module,

            title: this.props.moduleTitle,

            activeUser: {},

            activeRequests: [],
            activeLoanerBadges: [],

            rowsPerPage: 12,
            tablePage: 0,
            tablePageLoanerBadges: 0,

            requestDialogOpen: false,
            requestId: 0,

            // loaner badges modal
            newLoanerBadgeOpen: false,
            loanerBadgeId: 0,
            updateLoanerBadge: false,

            requestFilterOpen: false,
            loanerFilterOpen: false,

            enableBadgeReturn: false,

            dialogsReadOnly: this.props.module !== "activerequests",

            visitorRequestExpanded: true,
            loanerBadgeExpanded: true,

            visitorsTablePageOrderBy: "4",
            visitorsTablePageOrder: "asc",

            loanersTablePageOrderBy: "4L",
            loanersTablePageOrder: "asc",

            activeRequestFilter: false,
            activeRequestFilterData: {},
            activeRequestFilterRefresh: "",

            loanersBadgeFilter: false,
            loanersBadgeFilterData: {},
            loanersBadgeFilterRefresh: {},

            deleteLoanerBadgeConfirmationOpen: false,
            deleteRequestConfirmationOpen: false,
        };

        this.getActiveUser = this.getActiveUser.bind(this);
        this.beginGetRequests = this.beginGetRequests.bind(this);
        this.beginGetLoanerBadges = this.beginGetLoanerBadges.bind(this);
        this.getRequests = this.getRequests.bind(this);
        this.getLoanerBadges = this.getLoanerBadges.bind(this);
        this.addLoanerBadge = this.addLoanerBadge.bind(this);
        this.updateLoanerBadge = this.updateLoanerBadge.bind(this);
        this.getDeleteLoanerBadgeResult =
            this.getDeleteLoanerBadgeResult.bind(this);
        this.getDeleteRequestResult = this.getDeleteRequestResult.bind(this);

        this.tableChangePage = this.tableChangePage.bind(this);
        this.loanersTableChangePage = this.loanersTableChangePage.bind(this);

        this.loanerBadgeCloseHandler = this.loanerBadgeCloseHandler.bind(this);
        this.requestFilterCloseHandler =
            this.requestFilterCloseHandler.bind(this);
        this.loanerFilterCloseHandler =
            this.loanerFilterCloseHandler.bind(this);
        this.deleteLoanerBadgeCloseHandler =
            this.deleteLoanerBadgeCloseHandler.bind(this);
        this.deleteRequestCloseHandler =
            this.deleteRequestCloseHandler.bind(this);

        this.handleVisitorsTableSortClick =
            this.handleVisitorsTableSortClick.bind(this);
        this.handleLoanersTableSortClick =
            this.handleLoanersTableSortClick.bind(this);

        this.getActiveRequestsComponent =
            this.getActiveRequestsComponent.bind(this);
        this.getArchivedRequestsComponent =
            this.getArchivedRequestsComponent.bind(this);
        this.getActiveModule = this.getActiveModule.bind(this);
        this.getDeleteLoanerBadgeLink =
            this.getDeleteLoanerBadgeLink.bind(this);
        this.getDeleteActiveRequestLink =
            this.getDeleteActiveRequestLink.bind(this);

        this.getVisitorRequestsComponent =
            this.getVisitorRequestsComponent.bind(this);
        this.getArchivedVisitorRequestsComponent =
            this.getArchivedVisitorRequestsComponent.bind(this);

        this.getLoanerBadgesComponent =
            this.getLoanerBadgesComponent.bind(this);
        this.getArchivedLoanerBadgesComponent =
            this.getArchivedLoanerBadgesComponent.bind(this);

        ApiCallGetActiveUser(this.props.authToken, this.getActiveUser);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.activeRequestFilter !== this.state.activeRequestFilter ||
            prevState.activeRequestFilterData !==
                this.state.activeRequestFilterData ||
            prevState.activeRequestFilterRefresh !==
                this.state.activeRequestFilterRefresh
        ) {
            this.beginGetRequests();
        }

        if (
            prevState.loanersBadgeFilter !== this.state.loanersBadgeFilter ||
            prevState.loanersBadgeFilterData !==
                this.state.loanersBadgeFilterData ||
            prevState.loanersBadgeFilterRefresh !==
                this.state.loanersBadgeFilterRefresh
        ) {
            this.beginGetLoanerBadges();
        }
    }

    getComponentHidden(component) {
        return GetPer(this.state.activeUser, false, component, "main");
    }

    beginGetRequests() {
        this.setState({
            loadingActive: true,
        });

        let e = this.state.activeRequestFilterData.employeeName;
        let v = this.state.activeRequestFilterData.visitType;
        let j = this.state.activeRequestFilterData.jobNumber;
        let enf = this.state.activeRequestFilterData.entryDateFrom;
        let ent = this.state.activeRequestFilterData.entryDateTo;

        if (this.props.module === "activerequests") {
            if (this.state.activeRequestFilter === false)
                ApiCallGetActiveRequests(
                    this.props.authToken,
                    false,
                    "",
                    "",
                    "",
                    "",
                    "",
                    this.getRequests
                );
            else {
                ApiCallGetActiveRequests(
                    this.props.authToken,
                    true,
                    e,
                    v,
                    j,
                    enf,
                    ent,
                    this.getRequests
                );
            }
        } else {
            if (this.state.activeRequestFilter === false)
                ApiCallGetInactiveRequests(
                    this.props.authToken,
                    false,
                    "",
                    "",
                    "",
                    "",
                    "",
                    this.getRequests
                );
            else {
                ApiCallGetInactiveRequests(
                    this.props.authToken,
                    true,
                    e,
                    v,
                    j,
                    enf,
                    ent,
                    this.getRequests
                );
            }
        }
    }

    beginGetLoanerBadges() {
        this.setState({
            loadingActive: true,
        });

        //console.log(this.state.loanersBadgeFilterData);

        // employeeId: "0300"
        // badgeNumber: "601"
        // entryDateFrom: Tue Apr 14 2020 21:27:00 GMT+0200 (srednjoeuropsko ljetno vrijeme) {}
        // employeeName: "Riccardi, Emily S."

        let e = this.state.loanersBadgeFilterData.employeeId;
        let b = this.state.loanersBadgeFilterData.badgeNumber;
        let enf = this.state.loanersBadgeFilterData.entryDateFrom;

        if (this.props.module === "activerequests") {
            if (this.state.loanersBadgeFilter === false) {
                ApiCallGetLoanerBadges(
                    this.props.authToken,
                    false,
                    e,
                    b,
                    enf,
                    this.getLoanerBadges
                );
            } else {
                ApiCallGetLoanerBadges(
                    this.props.authToken,
                    true,
                    e,
                    b,
                    enf,
                    this.getLoanerBadges
                );
            }
        } else {
            if (this.state.loanersBadgeFilter === false) {
                ApiCallGetInactiveLoanerBadges(
                    this.props.authToken,
                    false,
                    e,
                    b,
                    enf,
                    this.getLoanerBadges
                );
            } else {
                ApiCallGetInactiveLoanerBadges(
                    this.props.authToken,
                    true,
                    e,
                    b,
                    enf,
                    this.getLoanerBadges
                );
            }
        }
    }

    getActiveUser(invalid, data) {
        this.setState({
            activeUser: data,
        });

        if (this.props.module === "archivedrequests") {
            // set filter for 30 days by default, for archived requests

            var filter = this.state.activeRequestFilterData;
            var filterLoaners = this.state.loanersBadgeFilterData;

            var tmp = new Date();
            tmp.setDate(tmp.getDate() - 30);

            filter.entryDateFrom = tmp;
            filter.employeeName = "";
            filter.visitType = "";
            filter.jobNumber = "";
            filter.entryDateTo = null;

            filterLoaners.entryDateFrom = tmp;
            filterLoaners.employeeId = "";
            filterLoaners.badgeNumber = "";

            this.setState({
                activeRequestFilter: true,
                activeRequestFilterData: filter,
                loanersBadgeFilter: true,
                loanersBadgeFilterData: filterLoaners,
            });
        } else {
            this.beginGetRequests();
        }
    }

    getRequests(invalid, data) {
        this.setState({
            activeRequests: data,
        });

        // if (this.props.module === "activerequests") {

        //     ApiCallGetLoanerBadges(this.props.authToken, false, "", "", "", this.getLoanerBadges);
        // }
        // else {

        //     ApiCallGetInactiveLoanerBadges(this.props.authToken, false, "", "", "", this.getLoanerBadges);
        // }

        this.beginGetLoanerBadges();
    }

    addLoanerBadge(invalid, data) {
        // this.setState(
        //     { loadingActive: false }
        // );

        ApiCallGetLoanerBadges(
            this.props.authToken,
            false,
            "",
            "",
            "",
            this.getLoanerBadges
        );
    }

    updateLoanerBadge(invalid, data) {
        ApiCallGetLoanerBadges(
            this.props.authToken,
            false,
            "",
            "",
            "",
            this.getLoanerBadges
        );
    }

    getDeleteLoanerBadgeResult(invalid, data) {
        ApiCallGetLoanerBadges(
            this.props.authToken,
            false,
            "",
            "",
            "",
            this.getLoanerBadges
        );
    }

    getDeleteRequestResult(invalid, data) {
        this.beginGetRequests();
    }

    tableChangePage(event, newPage) {
        this.setState({ tablePage: newPage });
    }

    loanersTableChangePage(event, newPage) {
        this.setState({ tablePageLoanerBadges: newPage });
    }

    loanerBadgeCloseHandler(save, updateExisting, data) {
        if (save === false) {
            this.setState({
                newLoanerBadgeOpen: false,
            });
            return;
        }

        // requestNumber: 4
        // date: "2020-03-31T00:00:00"
        // employeeId: "0305"
        // employeeName: "Newman, Justin K."
        // badgeNumber: "602"
        // badgeReturned: false

        // let tmp = {
        //     requestNumber:data.requestNumber,
        //     date:data.date,
        //     employeeId:data.employeeId,
        //     employeeName:data.employeeName,
        //     badgeNumber:data.badgeNumber.cardId,
        //     badgeReturned:data.badgeReturned,
        // };

        let tmp = JSON.parse(JSON.stringify(data));
        tmp.badgeNumber = tmp.badgeNumber.cardId;

        if (updateExisting === true) {
            ApiCallUpdateLoanerBadge(
                this.props.authToken,
                tmp,
                this.updateLoanerBadge
            );
        } else {
            ApiCallAddLoanerBadge(
                this.props.authToken,
                tmp,
                this.addLoanerBadge
            );
        }

        this.setState({
            newLoanerBadgeOpen: false,
            loadingActive: true,
        });
    }

    requestFilterCloseHandler(dialogResultsOk, filter) {
        if (dialogResultsOk === false) {
            this.setState({ requestFilterOpen: false });
            return;
        }

        this.setState({
            requestFilterOpen: false,
            activeRequestFilter: true,
            activeRequestFilterData: filter,
            tablePage: 0,
            activeRequestFilterRefresh: new Date().toLocaleTimeString(),
        });
    }

    loanerFilterCloseHandler(dialogResultsOk, filter) {
        if (dialogResultsOk === false) {
            this.setState({ loanerFilterOpen: false });
            return;
        }

        this.setState({
            loanerFilterOpen: false,
            loanersBadgeFilter: true,
            loanersBadgeFilterData: filter,
            tablePageLoanerBadges: 0,
            loanersBadgeFilterRefresh: new Date().toLocaleTimeString(),
        });
    }

    getLoanerBadges(invalid, data) {
        this.setState({ loadingActive: false, activeLoanerBadges: data });
    }

    getWarningIcon(expired, title) {
        if (expired)
            return (
                <Tooltip title={title}>
                    <WarningIcon
                        style={{ verticalAlign: "middle" }}
                        color="error"
                        fontSize="small"
                    />
                </Tooltip>
            );
        else return <Fragment />;
    }

    getCompletedIcon(completed, title) {
        if (completed)
            return (
                <Tooltip title={title}>
                    <AssignmentTurnedInIcon
                        style={{ verticalAlign: "middle" }}
                        color="primary"
                        fontSize="small"
                    />
                </Tooltip>
            );
        else return <Fragment />;
    }

    getWifiIcon(wifi) {
        if (wifi)
            return (
                <WifiIcon
                    style={{ verticalAlign: "middle", color: green[500] }}
                    fontSize="small"
                />
            );
        else return <Fragment />;
    }

    getNoRequestsMessage(visitorRequests) {
        if (visitorRequests) {
            if (this.state.activeRequests.length === 0) {
                return (
                    <TableRow>
                        <TableCell colSpan={12}>
                            <Alert variant="outlined" color="info">
                                No Visitor Requests Available
                            </Alert>
                        </TableCell>
                    </TableRow>
                );
            } else {
                return <></>;
            }
        } else {
            if (this.state.activeLoanerBadges.length === 0) {
                return (
                    <TableRow>
                        <TableCell colSpan={7}>
                            <Alert variant="outlined" color="info">
                                No Loaner Badges Available
                            </Alert>
                        </TableCell>
                    </TableRow>
                );
            } else {
                return <></>;
            }
        }
    }

    getRequestFilterActiveComponent() {
        if (this.state.activeRequestFilter === false) return <Fragment />;

        return (
            <>
                <Paper
                    elevation={0}
                    style={{
                        backgroundColor: blue[100],
                        marginTop: "3px",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="caption">
                        <Link
                            href="#"
                            style={{ fontWeight: "bold", color: blue[700] }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    activeRequestFilter: false,
                                    tablePage: 0,
                                    activeRequestFilterData: {},
                                });
                            }}
                        >
                            Click here
                        </Link>{" "}
                        to remove search filter
                    </Typography>
                </Paper>
            </>
        );
    }

    getLoanerBadgeFilterActiveComponent() {
        if (this.state.loanersBadgeFilter === false) return <Fragment />;

        return (
            <>
                <Paper
                    elevation={0}
                    style={{
                        backgroundColor: blue[100],
                        marginTop: "3px",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="caption">
                        <Link
                            href="#"
                            style={{ fontWeight: "bold", color: blue[700] }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    loanersBadgeFilter: false,
                                    tablePageLoanerBadges: 0,
                                    loanersBadgeFilterData: {},
                                });
                            }}
                        >
                            Click here
                        </Link>{" "}
                        to remove search filter
                    </Typography>
                </Paper>
            </>
        );
    }

    descendingComparator(a, b, orderBy) {
        let prop = "requestedBy";

        switch (orderBy) {
            case "1":
            default:
                break;

            case "2":
                prop = "type";
                break;

            case "3":
                prop = "jobName";
                break;

            case "4":
                prop = "entryDateNice";
                break;

            case "5":
                prop = "exitDateNice";
                break;

            case "1L":
                prop = "employeeId";
                break;
            case "2L":
                prop = "employeeName";
                break;
            case "3L":
                prop = "badgeNumber";
                break;
            case "4L":
                prop = "dateNice";
                break;
        }

        let propA = a[prop];
        let propB = b[prop];

        if (prop === "entryDateNice") {
            propA = new Date(a["entryDate"]);
            propB = new Date(b["entryDate"]);
        }

        if (prop === "exitDateNice") {
            propA = new Date(a["exitDate"]);
            propB = new Date(b["exitDate"]);
        }

        if (prop === "dateNice") {
            propA = new Date(a["date"]);
            propB = new Date(b["date"]);
        }

        if (propB < propA) return -1;
        if (propB > propA) return 1;

        return 0;
    }

    getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);

            // if (a[0].requestedBy < b[0].requestedBy)
            //     return -1;

            // if (a[0].requestedBy > b[0].requestedBy)
            //     return 1;

            // return 0;

            if (order !== 0) return order;
            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    }

    handleVisitorsTableSortClick(column) {
        let sort = this.state.visitorsTablePageOrder;

        sort = sort === "asc" ? "desc" : "asc";

        this.setState({
            visitorsTablePageOrder: sort,
            visitorsTablePageOrderBy: column,
        });
    }

    handleLoanersTableSortClick(column) {
        let sort = this.state.loanersTablePageOrder;

        sort = sort === "asc" ? "desc" : "asc";

        this.setState({
            loanersTablePageOrder: sort,
            loanersTablePageOrderBy: column,
        });
    }

    deleteLoanerBadgeCloseHandler(result) {
        this.setState({ deleteLoanerBadgeConfirmationOpen: false });

        if (result === true) {
            this.setState({ loadingActive: true });

            ApiCallDeleteLoanerBadge(
                this.props.authToken,
                this.state.loanerBadgeId,
                this.getDeleteLoanerBadgeResult
            );
        }
    }

    deleteRequestCloseHandler(result) {
        // Zoki
        this.setState({ deleteRequestConfirmationOpen: false });

        // console.log("Delete?", result);
        // console.log("Request", this.state.requestId);

        if (result === true) {
            this.setState({ loadingActive: true });

            ApiCallDeleteRequest(
                this.props.authToken,
                this.state.requestId,
                this.getDeleteRequestResult
            );
        }
    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.loadingActive}
                spinner
                text="Please wait..."
            >
                <Container component="main" maxWidth="xl">
                    <RequestDialog
                        authToken={this.props.authToken}
                        isUserAdmin={this.state.activeUser.persona === 1}
                        requestId={this.state.requestId}
                        open={this.state.requestDialogOpen}
                        closeHandler={(confirm) => {
                            this.setState({
                                requestDialogOpen: false,
                            });

                            if (confirm) {
                                this.beginGetRequests();
                            }
                        }}
                    />

                    <NewLoanerBadge
                        authToken={this.props.authToken}
                        open={this.state.newLoanerBadgeOpen}
                        activeUser={this.state.activeUser}
                        closeHandler={this.loanerBadgeCloseHandler}
                        enableBadgeReturn={this.state.enableBadgeReturn}
                        loanerBadgeId={this.state.loanerBadgeId}
                        updateLoanerBadge={this.state.updateLoanerBadge}
                        readOnly={
                            this.state.dialogsReadOnly ||
                            !this.state.activeUser ||
                            this.state.activeUser.persona !== 1
                        }
                    />

                    <RequestFilter
                        authToken={this.props.authToken}
                        open={this.state.requestFilterOpen}
                        closeHandler={this.requestFilterCloseHandler}
                    />

                    <LoanerFilter
                        authToken={this.props.authToken}
                        open={this.state.loanerFilterOpen}
                        closeHandler={this.loanerFilterCloseHandler}
                    />

                    <ConfirmDialog
                        open={this.state.deleteLoanerBadgeConfirmationOpen}
                        title="Delete loaner badge?"
                        message="Please confirm you want to delete loaner badge"
                        closeHandler={this.deleteLoanerBadgeCloseHandler}
                    />

                    <ConfirmDialog
                        open={this.state.deleteRequestConfirmationOpen}
                        title="Delete active request?"
                        message="Please confirm you want to delete active request"
                        closeHandler={this.deleteRequestCloseHandler}
                    />

                    <Box m="1rem" />

                    <Grid container justify="center" spacing={4}>
                        <Grid item xs={11}>
                            {this.getActiveModule()}
                        </Grid>
                    </Grid>
                </Container>
            </LoadingOverlay>
        );
    }

    getActiveRequestsComponent() {
        return (
            <>
                <ExpansionPanel
                    expanded={this.state.visitorRequestExpanded}
                    onChange={(e, expanded) => {
                        this.setState({ visitorRequestExpanded: expanded });
                    }}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                        >
                            Visitors
                        </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        {this.getVisitorRequestsComponent()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    expanded={this.state.loanerBadgeExpanded}
                    onChange={(e, expanded) => {
                        this.setState({ loanerBadgeExpanded: expanded });
                    }}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                        >
                            Loaners
                        </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        {this.getLoanerBadgesComponent()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </>
        );
    }

    getArchivedRequestsComponent() {
        return (
            <>
                <ExpansionPanel
                    expanded={this.state.visitorRequestExpanded}
                    onChange={(e, expanded) => {
                        this.setState({ visitorRequestExpanded: expanded });
                    }}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                        >
                            Visitors Archive
                        </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        {this.getArchivedVisitorRequestsComponent()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    expanded={this.state.loanerBadgeExpanded}
                    onChange={(e, expanded) => {
                        this.setState({ loanerBadgeExpanded: expanded });
                    }}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                        >
                            Loaners Archive
                        </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        {this.getArchivedLoanerBadgesComponent()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </>
        );
    }

    getActiveModule() {
        if (this.state.module === "activerequests")
            return this.getActiveRequestsComponent();
        else return this.getArchivedRequestsComponent();
    }

    isMyRequest(req, user) {
        let first = req.email.toLowerCase();
        let second = user.email.toLowerCase();

        return first === second || user.persona === 1;
    }

    getDeleteLoanerBadgeLink(req) {
        // Reserver for future use (maybe)
        return <></>;

        // if (this.state.activeUser.persona !== 1 || req.completed === true)
        //     return (<></>);

        // return (
        //     <>
        //         &nbsp;&nbsp;
        //         <Link href="#" onClick={() => {

        //             this.setState({ deleteLoanerBadgeConfirmationOpen: true, loanerBadgeId: req.id });

        //         }}>Delete</Link>
        //     </>
        // );
    }

    getDeleteActiveRequestLink(req) {
        if (req.noBadgesAssigned === false) return <></>;

        if (!this.isMyRequest(req, this.state.activeUser)) return <></>;

        return (
            <>
                &nbsp;&nbsp;
                <Link
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            deleteRequestConfirmationOpen: true,
                            requestId: req.requestNumber,
                        });
                    }}
                >
                    Delete
                </Link>
            </>
        );
    }

    getVisitorRequestsComponent() {
        return (
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                            width="2%"
                        ></TableCell>

                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                        ></TableCell>
                        {/* <TableCell>Request No.</TableCell> */}
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("1");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "1"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "1"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Employee
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("2");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "2"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "2"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Type
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("3");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "3"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "3"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Project
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("4");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "4"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "4"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Entry Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("5");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "5"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "5"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Exit Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            Visiting Company
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            Guest Count
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            Badges
                        </TableCell>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                            width="2%"
                        ></TableCell>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                            width="15%"
                        >
                            Notes
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {this.getNoRequestsMessage(true)}

                    {this.stableSort(
                        this.state.activeRequests,
                        this.getComparator(
                            this.state.visitorsTablePageOrder,
                            this.state.visitorsTablePageOrderBy
                        )
                    )
                        .slice(
                            this.state.tablePage * this.state.rowsPerPage,
                            this.state.tablePage * this.state.rowsPerPage +
                                this.state.rowsPerPage
                        )
                        .map((req) => {
                            return (
                                <TableRow
                                    key={"row_" + req.requestNumber}
                                    style={{
                                        backgroundColor: req.expired
                                            ? "#cbcbcb"
                                            : "",
                                        opacity: req.completed ? "0.5" : "",
                                    }}
                                >
                                    <TableCell>
                                        {this.getWarningIcon(
                                            req.expired,
                                            "Request expired - 1 or more badges are not returned"
                                        )}
                                        {this.getCompletedIcon(
                                            req.completed,
                                            "All badges returned"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            <Link
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        requestId:
                                                            req.requestNumber,
                                                        requestDialogOpen: true,
                                                    });
                                                }}
                                            >
                                                {this.isMyRequest(
                                                    req,
                                                    this.state.activeUser
                                                )
                                                    ? "Edit"
                                                    : "Details"}
                                            </Link>
                                            {this.getDeleteActiveRequestLink(
                                                req
                                            )}
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell><Typography variant="caption">{req.requestNumber}</Typography></TableCell> */}
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.requestedBy}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.type}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.jobNo + " " + req.jobName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {formatDate(req.entryDate)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {formatDate(req.exitDate)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.guestCompany}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.guests}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.badges.join(", ")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {this.getWifiIcon(req.wifi)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            maxWidth: "10px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Typography variant="caption">
                                            {req.comments}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={12}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        this.setState({
                                            requestDialogOpen: true,
                                            requestId: 0,
                                        });
                                    }}
                                >
                                    Add
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<SearchIcon fontSize="small" />}
                                    onClick={() => {
                                        this.setState({
                                            requestFilterOpen: true,
                                        });
                                    }}
                                >
                                    Search
                                </Button>
                                <div style={{ flexGrow: 1 }} />
                                <div style={{ flexGrow: 1 }}>
                                    {this.getRequestFilterActiveComponent()}
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    count={this.state.activeRequests.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.tablePage}
                                    onPageChange={this.tableChangePage}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        );
    }

    getArchivedVisitorRequestsComponent() {
        return (
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                        ></TableCell>
                        {/* <TableCell>Request No.</TableCell> */}
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("1");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "1"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "1"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Employee
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("2");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "2"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "2"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Type
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("3");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "3"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "3"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Project
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("4");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "4"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "4"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Entry Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleVisitorsTableSortClick("5");
                                }}
                                active={
                                    this.state.visitorsTablePageOrderBy === "5"
                                }
                                direction={
                                    this.state.visitorsTablePageOrderBy === "5"
                                        ? this.state.visitorsTablePageOrder
                                        : "asc"
                                }
                            >
                                Exit Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            Visiting Company
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            Guest Count
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            Badges
                        </TableCell>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                            width="2%"
                        ></TableCell>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                            width="15%"
                        >
                            Notes
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {this.getNoRequestsMessage(true)}

                    {this.stableSort(
                        this.state.activeRequests,
                        this.getComparator(
                            this.state.visitorsTablePageOrder,
                            this.state.visitorsTablePageOrderBy
                        )
                    )
                        .slice(
                            this.state.tablePage * this.state.rowsPerPage,
                            this.state.tablePage * this.state.rowsPerPage +
                                this.state.rowsPerPage
                        )
                        .map((req) => {
                            return (
                                <TableRow key={"row_" + req.requestNumber}>
                                    <TableCell>
                                        <Typography variant="caption">
                                            <Link
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        requestId:
                                                            req.requestNumber,
                                                        requestDialogOpen: true,
                                                    });
                                                }}
                                            >
                                                Details
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell><Typography variant="caption">{req.requestNumber}</Typography></TableCell> */}
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.requestedBy}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.type}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.jobNo + " " + req.jobName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {formatDate(req.entryDate)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {formatDate(req.exitDate)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.guestCompany}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.guests}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.badges.join(", ")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {this.getWifiIcon(req.wifi)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            maxWidth: "10px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Typography variant="caption">
                                            {req.comments}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={11}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<SearchIcon fontSize="small" />}
                                    onClick={() => {
                                        this.setState({
                                            requestFilterOpen: true,
                                        });
                                    }}
                                >
                                    Search
                                </Button>
                                <div style={{ flexGrow: 1 }} />
                                <div style={{ flexGrow: 1 }}>
                                    {this.getRequestFilterActiveComponent()}
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    count={this.state.activeRequests.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.tablePage}
                                    onPageChange={this.tableChangePage}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        );
    }

    getLoanerBadgesComponent() {
        return (
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                            width="2%"
                        ></TableCell>

                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                        ></TableCell>
                        {/* <TableCell>Request No.</TableCell> */}
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("1L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "1L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "1L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Employee Id
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("2L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "2L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "2L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Employee Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("3L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "3L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "3L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Badge Number
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("4L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "4L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "4L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Date
                            </TableSortLabel>
                        </TableCell>
                        {/* <TableCell></TableCell> */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {this.getNoRequestsMessage(false)}

                    {this.stableSort(
                        this.state.activeLoanerBadges,
                        this.getComparator(
                            this.state.loanersTablePageOrder,
                            this.state.loanersTablePageOrderBy
                        )
                    )
                        .slice(
                            this.state.tablePageLoanerBadges *
                                this.state.rowsPerPage,
                            this.state.tablePageLoanerBadges *
                                this.state.rowsPerPage +
                                this.state.rowsPerPage
                        )
                        .map((req) => {
                            return (
                                <TableRow
                                    key={"row_" + req.id}
                                    style={{
                                        backgroundColor: req.expired
                                            ? "#cbcbcb"
                                            : "",
                                        opacity: req.completed ? "0.5" : "",
                                    }}
                                >
                                    <TableCell>
                                        {this.getWarningIcon(
                                            req.expired,
                                            "Request expired - badge not returned"
                                        )}
                                        {this.getCompletedIcon(
                                            req.completed,
                                            "Badge returned"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            <Link
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        newLoanerBadgeOpen: true,
                                                        loanerBadgeId: req.id,
                                                        updateLoanerBadge: true,
                                                        enableBadgeReturn: true,
                                                    });
                                                }}
                                            >
                                                {this.state.activeUser
                                                    .persona === 1
                                                    ? "Edit"
                                                    : "Details"}
                                            </Link>

                                            {this.getDeleteLoanerBadgeLink(req)}
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell><Typography variant="caption">{req.id}</Typography></TableCell> */}
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.employeeId}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.employeeName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.badgeNumber}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.dateNice}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    style={{
                                        display: this.getComponentHidden(
                                            "newloanerrequest"
                                        )
                                            ? "none"
                                            : "",
                                    }}
                                    size="small"
                                    variant="outlined"
                                    startIcon={<AddIcon fontSize="small" />}
                                    onClick={() => {
                                        this.setState({
                                            newLoanerBadgeOpen: true,
                                            loanerBadgeId: 0,
                                            updateLoanerBadge: false,
                                            enableBadgeReturn: false,
                                        });
                                    }}
                                >
                                    Add
                                </Button>
                                <div style={{ flexGrow: 1 }} />
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    count={this.state.activeLoanerBadges.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.tablePageLoanerBadges}
                                    onPageChange={this.loanersTableChangePage}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        );
    }

    getArchivedLoanerBadgesComponent() {
        return (
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ backgroundColor: grey[100] }}
                        ></TableCell>
                        {/* <TableCell>Request No.</TableCell> */}
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("1L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "1L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "1L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Employee Id
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("2L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "2L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "2L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Employee Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("3L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "3L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "3L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Badge Number
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ backgroundColor: grey[100] }}>
                            <TableSortLabel
                                onClick={() => {
                                    this.handleLoanersTableSortClick("4L");
                                }}
                                active={
                                    this.state.loanersTablePageOrderBy === "4L"
                                }
                                direction={
                                    this.state.loanersTablePageOrderBy === "4L"
                                        ? this.state.loanersTablePageOrder
                                        : "asc"
                                }
                            >
                                Date
                            </TableSortLabel>
                        </TableCell>
                        {/* <TableCell></TableCell> */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {this.getNoRequestsMessage(false)}

                    {this.stableSort(
                        this.state.activeLoanerBadges,
                        this.getComparator(
                            this.state.loanersTablePageOrder,
                            this.state.loanersTablePageOrderBy
                        )
                    )
                        .slice(
                            this.state.tablePageLoanerBadges *
                                this.state.rowsPerPage,
                            this.state.tablePageLoanerBadges *
                                this.state.rowsPerPage +
                                this.state.rowsPerPage
                        )
                        .map((req) => {
                            return (
                                <TableRow key={"row_" + req.id}>
                                    <TableCell>
                                        <Typography variant="caption">
                                            <Link
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        newLoanerBadgeOpen: true,
                                                        loanerBadgeId: req.id,
                                                        updateLoanerBadge: true,
                                                        enableBadgeReturn: true,
                                                    });
                                                }}
                                            >
                                                Details
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell><Typography variant="caption">{req.id}</Typography></TableCell> */}
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.employeeId}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.employeeName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.badgeNumber}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {req.dateNice}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<SearchIcon fontSize="small" />}
                                    onClick={() => {
                                        this.setState({
                                            loanerFilterOpen: true,
                                        });
                                    }}
                                >
                                    Search
                                </Button>
                                <div style={{ flexGrow: 1 }} />
                                <div style={{ flexGrow: 1 }}>
                                    {this.getLoanerBadgeFilterActiveComponent()}
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    count={this.state.activeLoanerBadges.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.tablePageLoanerBadges}
                                    onPageChange={this.loanersTableChangePage}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        );
    }
}

export default MainForm;
