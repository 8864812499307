import DateFnsUtils from "@date-io/date-fns";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ApiCallGetProjects, ApiCallGetVisitTypes } from "../../Utils/ApiCalls";

class RequestFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: this.props.open,
            loadingActive: true,

            data: {
                employeeName: "",
                visitType: "",
                jobNumber: "",
                entryDateFrom: null,
                entryDateTo: null,
            },

            visitTypes: [],
            projects: [],
            selectedProject: { projNo: "", projName: "" },
        };

        this.getVisitTypes = this.getVisitTypes.bind(this);
        this.getProjects = this.getProjects.bind(this);

        ApiCallGetVisitTypes(this.props.authToken, this.getVisitTypes);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                dialogOpen: this.props.open,
            });
        }
    }

    getVisitTypes(invalid, data) {
        data.splice(0, 0, { visitType: "-" });

        let d = this.state.data;

        d.visitType = data[0].visitType;

        this.setState({
            visitTypes: data,
            data: d,
        });

        ApiCallGetProjects(this.props.authToken, this.getProjects);
    }

    getProjects(invalid, data) {
        let d = this.state.data;

        data.splice(0, 0, { projNo: "-", projName: "-" });

        d.jobNumber = data[0].projNo;

        this.setState({
            projects: data,
            data: d,
            selectedProject: data[0],
            loadingActive: false,
        });
    }

    render() {
        return (
            <Dialog maxWidth="sm" fullWidth={true} open={this.state.dialogOpen}>
                <DialogTitle>Search Visitor Requests</DialogTitle>

                <LoadingOverlay
                    active={this.state.loadingActive}
                    spinner
                    text="Please wait..."
                >
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    fullWidth
                                    label="Employee Name"
                                    size="small"
                                    value={this.state.data.employeeName}
                                    onChange={(e) => {
                                        let d = this.state.data;
                                        d.employeeName = e.target.value;
                                        this.setState({ data: d });
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={1}></Box>

                                <FormControl style={{ minWidth: "100%" }}>
                                    <InputLabel id="label-visittype">
                                        Visit Type
                                    </InputLabel>

                                    <Select
                                        labelId="label-visittype"
                                        variant="standard"
                                        margin="dense"
                                        fullWidth
                                        value={this.state.data.visitType}
                                        onChange={(e) => {
                                            let d = this.state.data;
                                            d.visitType = e.target.value;
                                            this.setState({ data: d });
                                        }}
                                    >
                                        {this.state.visitTypes.map((loc) => {
                                            return (
                                                <MenuItem
                                                    key={"key_" + loc.visitType}
                                                    value={loc.visitType}
                                                >
                                                    {loc.visitType}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    //disabled={this.state.readOnly === true}

                                    disableClearable
                                    value={this.state.selectedProject}
                                    onChange={(event, value) => {
                                        let data = this.state.data;
                                        data.jobNumber = value.projNo;
                                        this.setState({
                                            data: data,
                                            selectedProject: value,
                                        });
                                    }}
                                    autoHighlight
                                    options={this.state.projects}
                                    getOptionLabel={(option) =>
                                        option.projNo === "-"
                                            ? "-"
                                            : option.projNo +
                                              " " +
                                              option.projName
                                    }
                                    //inputProps={{ readOnly: this.state.readOnly === true }}

                                    name="autoCompleteCountry"
                                    key="autocompletecountry-key"
                                    renderOption={(option) => (
                                        <Typography
                                            variant="body2"
                                            component="span"
                                        >
                                            {option.projNo === "-"
                                                ? "-"
                                                : option.projNo +
                                                  " " +
                                                  option.projName}
                                        </Typography>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            name="textFieldCountry"
                                            key="textfieldcountry-key"
                                            margin="dense"
                                            fullWidth
                                            {...params}
                                            label="Project Number"
                                            variant="standard"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill

                                                readOnly:
                                                    this.state.readOnly ===
                                                    true,
                                            }}
                                        />
                                    )}
                                ></Autocomplete>
                            </Grid>

                            <Grid item xs={12}>
                                <Box m={3}></Box>
                            </Grid>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={6}>
                                    {/* <TextField variant="standard" margin="dense" fullWidth label="Proposed Entry Date" size="small" value={this.state.data.entryDate} onChange={(e) => { let data = this.state.data; data.entryDate = e.target.value; this.setState({ data: data }) }} /> */}

                                    <KeyboardDatePicker
                                        margin="dense"
                                        label="Entry Date From"
                                        format="MM/dd/yyyy"
                                        clearable
                                        value={this.state.data.entryDateFrom}
                                        onChange={(value) => {
                                            let data = this.state.data;
                                            data.entryDateFrom = value;
                                            this.setState({ data: data });
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        margin="dense"
                                        label="Entry Date To"
                                        format="MM/dd/yyyy"
                                        clearable
                                        value={this.state.data.entryDateTo}
                                        onChange={(value) => {
                                            let data = this.state.data;
                                            data.entryDateTo = value;
                                            this.setState({ data: data });
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.setState({ dialogOpen: false });
                                this.props.closeHandler(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            color="primary"
                            onClick={() => {
                                this.setState({ dialogOpen: false });
                                this.props.closeHandler(true, this.state.data);
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </LoadingOverlay>
            </Dialog>
        );
    }
}

export default RequestFilter;
