import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert, Autocomplete } from "@material-ui/lab";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  apiCreateRequest,
  apiGetCards,
  apiGetLocations,
  apiGetProjects,
  apiGetRequest,
  apiGetVisitTypes,
  apiUpdateRequest,
} from "../api";
import {
  Card,
  CardType,
  Location,
  Project,
  RequestData,
  VisitType,
} from "../types";
import { dateToLocalDateTime, localDateTimeToDate } from "../utils";

interface RequestDialogProps {
  authToken: string;
  isUserAdmin: boolean;
  requestId: number;
  open: boolean;
  closeHandler: (confirm: boolean) => void;
}

interface Guest {
  id: number;
  name: string;
  badgeId: string;
  badgeReturned: boolean;
  badgeCard?: Card;
}

function getDefaultEntryDateTime(): string {
  const date: Date = new Date();
  date.setMinutes(date.getMinutes() + 30);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
  return date.toISOString().slice(0, -1);
}

function getDefaultExitDateTime(): string {
  const date: Date = new Date();
  date.setMinutes(date.getMinutes() + 120);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
  return date.toISOString().slice(0, -1);
}

export default function RequestDialog(props: RequestDialogProps): ReactElement {
  const [errorMessage, setErrorMessage] = useState("");

  const [cards, setCards] = useState<Card[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [visitTypes, setVisitTypes] = useState<VisitType[]>([]);

  const [applicableCards, setApplicableCards] = useState<Card[]>([]);

  const [location, setLocation] = useState("");
  const [visitType, setVisitType] = useState("");
  const [projectNo, setProjectNo] = useState("");
  const [project, setProject] = useState<Project | null>(null);
  const [entryDateTime, setEntryDateTime] = useState("");
  const [exitDateTime, setExitDateTime] = useState("");
  const [guestCompany, setGuestCompany] = useState("");
  const [wifiRequired, setWiFiRequired] = useState(false);
  const [wifiDetails, setWiFiDetails] = useState("");
  const [noBadgeRequired, setNoBadgeRequired] = useState(false);
  const [noBadgeJustification, setNoBadgeJustification] = useState("");
  const [workingSpace, setWorkingSpace] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [notes, setNotes] = useState("");
  const [guests, setGuests] = useState<Guest[]>([]);

  const [isEditable, setIsEditable] = useState(false);
  const [isBadgeEditable, setIsBadgeEditable] = useState(false);

  const loadCards = useCallback(() => {
    apiGetCards(props.authToken)
      .then((data) => {
        setCards(data);
      })
      .catch(() => {
        setCards([]);
      });
  }, [props.authToken]);

  useEffect(() => {
    loadCards();

    apiGetLocations(props.authToken)
      .then((data) => {
        setLocations(data);
      })
      .catch(() => {
        setLocations([]);
      });

    apiGetProjects(props.authToken)
      .then((data) => {
        setProjects(data);
      })
      .catch(() => {
        setProjects([]);
      });

    apiGetVisitTypes(props.authToken)
      .then((data) => {
        setVisitTypes(data);
      })
      .catch(() => {
        setVisitTypes([]);
      });
  }, [props.authToken, loadCards]);

  useEffect(() => {
    const visitTypeObj: VisitType | undefined = visitTypes.find(
      (x) => x.visitType === visitType
    );
    const cardType: CardType | undefined = visitTypeObj?.cardType;

    if (cardType) {
      setApplicableCards(cards.filter((c) => c.cardType === cardType));
    } else {
      setApplicableCards([]);
    }

    // Clear cards that are of different type
    setGuests(
      guests.map((x) =>
        x.badgeCard?.cardType !== cardType
          ? { ...x, badgeId: "", badgeCard: undefined }
          : x
      )
    );

    // eslint-disable-next-line
  }, [props.authToken, cards, visitTypes, visitType]);

  useEffect(() => {
    if (props.open && props.requestId) {
      apiGetRequest(props.authToken, props.requestId)
        .then((data) => {
          setLocation(data.location);
          setVisitType(data.visitType);
          setProjectNo(data.jobNo);
          setGuestCompany(data.guestCompany);
          setEntryDateTime(dateToLocalDateTime(data.entryDateTime));
          setExitDateTime(dateToLocalDateTime(data.exitDateTime));
          setWiFiRequired(data.wifiRequired);
          setWiFiDetails(data.wifiDetails);
          setNoBadgeRequired(data.noBadgeRequired);
          setNoBadgeJustification(data.noBadgeJustification);
          setWorkingSpace(data.workingSpace);
          setRequestedBy(data.requestedBy);
          setNotes(data.notes);
          setGuests(
            data.guests.map((x) => ({
              id: x.id,
              name: x.name,
              badgeId: x.badgeId,
              badgeReturned: x.badgeReturned,
              badgeCard: cards.find((c) => c.cardId === x.badgeId),
            }))
          );
          setIsEditable(data.canUpdate);
          setIsBadgeEditable(data.canAssignBadges);
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    } else {
      if (props.open) {
        setLocation(locations.length > 0 ? locations[0].locationName : "");
        setVisitType(visitTypes.length > 0 ? visitTypes[0].visitType : "");
        setProjectNo(projects.length > 0 ? projects[0].projNo : "");
        setEntryDateTime(getDefaultEntryDateTime());
        setExitDateTime(getDefaultExitDateTime());
      } else {
        setLocation("");
        setVisitType("");
        setProjectNo("");
        setEntryDateTime("");
        setExitDateTime("");
      }

      setGuestCompany("");
      setWiFiRequired(false);
      setWiFiDetails("");
      setNoBadgeRequired(false);
      setNoBadgeJustification("");
      setWorkingSpace("");
      setRequestedBy("");
      setNotes("");
      setGuests([]);

      setIsEditable(true);
      setIsBadgeEditable(props.isUserAdmin);

      setErrorMessage("");
    }
  }, [
    props.authToken,
    props.isUserAdmin,
    props.requestId,
    props.open,
    cards,
    locations,
    visitTypes,
    projects,
  ]);

  useEffect(() => {
    setProject(projects.find((x) => x.projNo === projectNo) || null);
  }, [projects, projectNo]);

  const addGuest = () => {
    setGuests(
      guests.concat([{ id: 0, name: "", badgeId: "", badgeReturned: false }])
    );
  };

  const setGuestName = (guest: Guest, name: string) => {
    setGuests(guests.map((x) => (x === guest ? { ...x, name } : x)));
  };

  const setGuestBadgeCard = (guest: Guest, badgeCard: Card | undefined) => {
    const badgeId: string = badgeCard?.cardId || "";
    setGuests(
      guests.map((x) => (x === guest ? { ...x, badgeId, badgeCard } : x))
    );
  };

  const setGuestBadgeReturned = (guest: Guest, badgeReturned: boolean) => {
    setGuests(guests.map((x) => (x === guest ? { ...x, badgeReturned } : x)));
  };

  const removeGuest = (guest: Guest) => {
    setGuests(guests.filter((x) => x !== guest));
  };

  const save = () => {
    const requestData: RequestData = {
      location: location,
      visitType: visitType,
      jobNo: projectNo,
      guestCompany: guestCompany,
      entryDateTime: localDateTimeToDate(entryDateTime),
      exitDateTime: localDateTimeToDate(exitDateTime),
      wifiRequired: wifiRequired,
      wifiDetails: wifiDetails,
      noBadgeRequired: noBadgeRequired,
      noBadgeJustification: noBadgeJustification,
      workingSpace: workingSpace,
      notes: notes,
      guests: guests.map((x) => ({
        id: x.id,
        name: x.name,
        badgeId: x.badgeId,
        badgeReturned: x.badgeReturned,
      })),
    };

    if (props.requestId) {
      apiUpdateRequest(props.authToken, props.requestId, requestData)
        .then((result) => {
          if (result) {
            setErrorMessage(JSON.stringify(result.errors));
          } else {
            props.closeHandler(true);

            // Reload cards because their allocations might have changed
            loadCards();
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    } else {
      apiCreateRequest(props.authToken, requestData)
        .then((result) => {
          if (result) {
            setErrorMessage(JSON.stringify(result.errors));
          } else {
            props.closeHandler(true);

            // Reload cards because their allocations might have changed
            loadCards();
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth={true} open={props.open}>
      <DialogTitle>
        {props.requestId ? "Request " + props.requestId : "New Request"}
      </DialogTitle>
      <DialogContent dividers>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small" margin="none">
              <InputLabel shrink>Location</InputLabel>
              <Select
                margin="none"
                value={location}
                onChange={(e) => setLocation(e.target.value as string)}
                disabled={!isEditable}
              >
                {locations.map((location) => (
                  <MenuItem value={location.locationName}>
                    {location.locationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small" margin="none">
              <InputLabel shrink>Visitor</InputLabel>
              <Select
                margin="none"
                value={visitType}
                onChange={(e) => setVisitType(e.target.value as string)}
                disabled={!isEditable}
              >
                {visitTypes.map((visitType) => (
                  <MenuItem value={visitType.visitType}>
                    {visitType.visitType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              size="small"
              value={project}
              options={projects}
              getOptionLabel={(option) => option.projNo + " " + option.projName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  margin="none"
                  label="Project"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(_, value) => {
                setProjectNo(value?.projNo || "");
              }}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              margin="none"
              label="Visiting Company"
              InputLabelProps={{ shrink: true }}
              value={guestCompany}
              onChange={(e) => setGuestCompany(e.target.value)}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              margin="none"
              type="datetime-local"
              label="Proposed Entry Date/Time"
              InputLabelProps={{ shrink: true }}
              value={entryDateTime}
              onChange={(e) => setEntryDateTime(e.target.value)}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              margin="none"
              type="datetime-local"
              label="Proposed Exit Date/Time"
              InputLabelProps={{ shrink: true }}
              value={exitDateTime}
              onChange={(e) => setExitDateTime(e.target.value)}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="Wi-Fi Required"
              control={
                <Switch
                  checked={wifiRequired}
                  onChange={(e) => setWiFiRequired(e.target.checked)}
                  disabled={!isEditable}
                />
              }
            />
            <Box ml={1}>
              {wifiRequired && (
                <TextField
                  fullWidth
                  size="small"
                  margin="none"
                  label="Duration of Wi-Fi and Location"
                  InputLabelProps={{ shrink: true }}
                  hidden={!wifiRequired}
                  value={wifiDetails}
                  onChange={(e) => setWiFiDetails(e.target.value)}
                  disabled={!isEditable}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="No badge to be issued"
              control={
                <Switch
                  checked={noBadgeRequired}
                  onChange={(e) => setNoBadgeRequired(e.target.checked)}
                  disabled={!isEditable}
                />
              }
            />
            <Box ml={1}>
              {noBadgeRequired && (
                <TextField
                  fullWidth
                  size="small"
                  margin="none"
                  label="Justification for no badge to be issued"
                  InputLabelProps={{ shrink: true }}
                  value={noBadgeJustification}
                  onChange={(e) => setNoBadgeJustification(e.target.value)}
                  disabled={!isEditable}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              margin="none"
              label="Office/Conference room"
              InputLabelProps={{ shrink: true }}
              value={workingSpace}
              onChange={(e) => setWorkingSpace(e.target.value)}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              disabled
              size="small"
              margin="none"
              label="Requested By"
              InputLabelProps={{ shrink: true }}
              value={requestedBy}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={2}
              size="small"
              margin="none"
              label="Notes"
              InputLabelProps={{ shrink: true }}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
        <Box my={1}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "lightgrey" }}>
                <TableCell>Guest Name</TableCell>
                {!noBadgeRequired && (
                  <>
                    <TableCell>Badge Number</TableCell>
                    <TableCell>Badge Returned</TableCell>
                  </>
                )}
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {guests.map((guest) => (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      value={guest.name}
                      onChange={(e) => setGuestName(guest, e.target.value)}
                      disabled={!isEditable}
                    />
                  </TableCell>
                  {!noBadgeRequired && (
                    <>
                      <TableCell>
                        <Autocomplete
                          fullWidth
                          options={applicableCards}
                          getOptionLabel={(option) => option.cardName}
                          getOptionDisabled={(option) =>
                            option.cardAllocations?.some(
                              (x) =>
                                x.requestNumber !== props.requestId &&
                                (!x.startDateTime ||
                                  x.startDateTime < exitDateTime) &&
                                (!x.endDateTime ||
                                  x.endDateTime > entryDateTime)
                            ) || false
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          value={guest.badgeCard || null}
                          onChange={(_, value) =>
                            setGuestBadgeCard(guest, value || undefined)
                          }
                          disabled={!isBadgeEditable}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          size="small"
                          checked={guest.badgeReturned}
                          onChange={(e) =>
                            setGuestBadgeReturned(guest, e.target.checked)
                          }
                          disabled={!isBadgeEditable}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => removeGuest(guest)}
                      disabled={!isEditable}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={addGuest}
          disabled={!isEditable}
        >
          Add
        </Button>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={save} disabled={!isEditable}>
          Save
        </Button>
        <Button color="primary" onClick={() => props.closeHandler(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
