import DateFnsUtils from "@date-io/date-fns";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ApiCallGetCards, ApiCallGetEmployees } from "../../Utils/ApiCalls";

class LoanerFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: this.props.open,
            loadingActive: true,

            employees: [],
            cards: [],
            selectedEmployee: {},
            selectedCard: {},

            data: {
                employeeId: "",
                badgeNumber: "",
                entryDateFrom: null,
            },
        };

        this.getEmployees = this.getEmployees.bind(this);
        this.getCards = this.getCards.bind(this);

        ApiCallGetEmployees(this.props.authToken, this.getEmployees);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                dialogOpen: this.props.open,
            });
        }
    }

    getEmployees(invalid, data) {
        data.splice(0, 0, { emp_Code: "", emp_Name: "" });

        let firstEmployee = data[0];
        let d = this.state.data;

        d.employeeId = firstEmployee.emp_Code;

        this.setState({
            employees: data,
            selectedEmployee: firstEmployee,
            data: d,
        });

        ApiCallGetCards(this.props.authToken, false, this.getCards);
    }

    getCards(invalid, data) {
        let firstCard = data[0];

        this.setState({
            loadingActive: false,
            selectedCard: firstCard,
            cards: data,
        });
    }

    render() {
        return (
            <Dialog maxWidth="sm" fullWidth={true} open={this.state.dialogOpen}>
                <DialogTitle>Search Loaner Badges</DialogTitle>

                <LoadingOverlay
                    active={this.state.loadingActive}
                    spinner
                    text="Please wait..."
                >
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disableClearable
                                    value={this.state.selectedEmployee}
                                    onChange={(event, value) => {
                                        let data = this.state.data;

                                        data.employeeId = value.emp_Code;
                                        data.employeeName = value.emp_Name;

                                        this.setState({
                                            data: data,
                                            selectedEmployee: value,
                                        });
                                    }}
                                    autoHighlight
                                    options={this.state.employees}
                                    getOptionLabel={(option) =>
                                        option.emp_Code === ""
                                            ? " "
                                            : option.emp_Code +
                                              " " +
                                              option.emp_Name
                                    }
                                    key="autocompleteemployees-key"
                                    renderOption={(option) => (
                                        <Typography
                                            variant="body2"
                                            component="span"
                                        >
                                            {option.emp_Code === ""
                                                ? "(none)"
                                                : option.emp_Code +
                                                  " " +
                                                  option.emp_Name}
                                        </Typography>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            key="textfieldemployee-key"
                                            size="small"
                                            margin="dense"
                                            fullWidth
                                            {...params}
                                            label="Employee"
                                            variant="standard"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                ></Autocomplete>
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    disableClearable
                                    value={this.state.selectedCard}
                                    onChange={(event, value) => {
                                        let data = this.state.data;

                                        data.badgeNumber = value.cardId;

                                        this.setState({
                                            data: data,
                                            selectedCard: value,
                                        });
                                    }}
                                    autoHighlight
                                    options={this.state.cards}
                                    getOptionLabel={(option) =>
                                        option.cardName === ""
                                            ? " "
                                            : option.cardName
                                    }
                                    key="autocompletecards-key"
                                    renderOption={(option) => (
                                        <Typography
                                            variant="body2"
                                            component="span"
                                        >
                                            {option.cardName === ""
                                                ? "(none)"
                                                : option.cardName}
                                        </Typography>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            key="textfieldcard-key"
                                            size="small"
                                            margin="dense"
                                            fullWidth
                                            {...params}
                                            label="Badge Number"
                                            variant="standard"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                ></Autocomplete>
                            </Grid>

                            <Grid item xs={12}>
                                <Box m={3}></Box>
                            </Grid>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={6}>
                                    {/* <TextField variant="standard" margin="dense" fullWidth label="Proposed Entry Date" size="small" value={this.state.data.entryDate} onChange={(e) => { let data = this.state.data; data.entryDate = e.target.value; this.setState({ data: data }) }} /> */}

                                    <KeyboardDatePicker
                                        margin="dense"
                                        label="Date From"
                                        format="MM/dd/yyyy"
                                        clearable
                                        value={this.state.data.entryDateFrom}
                                        onChange={(value) => {
                                            let data = this.state.data;
                                            data.entryDateFrom = value;
                                            this.setState({ data: data });
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.setState({ dialogOpen: false });
                                this.props.closeHandler(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            color="primary"
                            onClick={() => {
                                this.setState({ dialogOpen: false });
                                this.props.closeHandler(true, this.state.data);
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </LoadingOverlay>
            </Dialog>
        );
    }
}

export default LoanerFilter;
