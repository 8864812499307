import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Alert } from "@material-ui/lab";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { apiDeleteUser, apiGetActiveUser, apiGetUsers } from "../api";
import { User } from "../types";
import ConfirmDialog from "./Misc/ConfirmDialog";
import UserDialog from "./UserDialog";

interface UsersProps {
  authToken: string;
}

export default function Users(props: UsersProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const loadUsers = useCallback(() => {
    setIsLoading(true);
    apiGetUsers(props.authToken)
      .then((data) => {
        setIsLoading(false);
        setUsers(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  }, [props.authToken]);

  const deleteUser = () => {
    if (selectedUser) {
      setIsDeleting(true);
      apiDeleteUser(props.authToken, selectedUser.id)
        .then(() => {
          setIsDeleting(false);
          setErrorMessage("");

          loadUsers();
        })
        .catch((e) => {
          setIsDeleting(false);
          setErrorMessage(e.message);
        });
    }
  };

  useEffect(() => {
    loadUsers();

    apiGetActiveUser(props.authToken)
      .then((data) => {
        setIsUserAdmin(data.persona === 1);
      })
      .catch();
  }, [props.authToken, loadUsers]);

  const handleCreateButtonClick = () => {
    setSelectedUser({ id: 0, name: "", email: "", persona: "" });
    setEditDialogOpen(true);
  };

  const handleEditButtonClick = (user: User) => {
    setSelectedUser(user);
    setEditDialogOpen(true);
  };

  const handleDeleteButtonClick = (user: User) => {
    setSelectedUser(user);
    setDeleteDialogOpen(true);
  };

  const handleUserDialogClose = (ok: boolean) => {
    setEditDialogOpen(false);

    if (ok) {
      loadUsers();
    }
  };

  const handleConfirmDialogClose = (confirm: boolean) => {
    setDeleteDialogOpen(false);

    if (confirm) {
      deleteUser();
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: 10, marginBottom: 20 }}>
      <Backdrop open={isLoading || isDeleting} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {isUserAdmin && (
        <Box my={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleCreateButtonClick}
          >
            Create User
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow key="header">
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Persona</TableCell>
              {isUserAdmin && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.persona}</TableCell>
                {isUserAdmin && (
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleEditButtonClick(row)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteButtonClick(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UserDialog
        authToken={props.authToken}
        open={editDialogOpen}
        userId={selectedUser?.id || 0}
        closeHandler={handleUserDialogClose}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete user"
        message="Are you sure you want to delete this user?"
        closeHandler={handleConfirmDialogClose}
      />
    </Container>
  );
}
