import {
  AppBar,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FolderIcon from "@material-ui/icons/Folder";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PeopleIcon from "@material-ui/icons/People";
import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { version } from "../../package.json";

interface HeaderProps {
  isSignedIn: boolean;
  onLogoutClick: () => void;
}

export default function Header(props: HeaderProps): ReactElement {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <AppBar
      position="static"
      elevation={3}
      style={{ backgroundColor: grey[50] }}
    >
      <Toolbar>
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + "/dm_logo.png"}
            alt=""
            style={{
              height: "52px",
              verticalAlign: "middle",
              paddingRight: "2rem",
            }}
          />
        </Link>
        <Typography
          variant="subtitle2"
          color="inherit"
          noWrap
          style={{ color: grey[900], flexGrow: 1 }}
        >
          D&amp;M Entry Registration System&nbsp;
          <Typography
            variant="subtitle2"
            component="span"
            style={{ color: "#666" }}
          >
            version {version}
          </Typography>
        </Typography>
        {props.isSignedIn && (
          <Button
            endIcon={<MenuIcon />}
            variant="outlined"
            onClick={openDrawer}
          >
            Menu
          </Button>
        )}
      </Toolbar>
      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        <List style={{ width: 250 }}>
          <ListItem
            button
            component={Link}
            to="/"
            key="home"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </List>
        <Divider />
        <List style={{ width: 250 }}>
          <ListItem
            button
            component={Link}
            to="/"
            key="activereq"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <NotificationsActiveIcon />
            </ListItemIcon>
            <ListItemText primary="Active" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/archive"
            key="archreq"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary="Archive" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/users"
            key="usersreq"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key="logout" onClick={props.onLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
}
