import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { ReactElement } from "react";

interface AlertDialogProps {
  open: boolean;
  title: string;
  message: string;
  closeHandler: () => void;
}

export default function AlertDialog(props: AlertDialogProps): ReactElement {
  return (
    <Dialog maxWidth="sm" fullWidth={true} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => props.closeHandler()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
