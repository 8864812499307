import DateFnsUtils from "@date-io/date-fns";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
    ApiCallGetCards,
    ApiCallGetEmployees,
    ApiCallGetLoanerBadge,
    ApiCallValidateLoanerBadge,
} from "../../Utils/ApiCalls";
import { GetPer } from "../../Utils/PersonaPermissions";
import AlertDialog from "../Misc/AlertDialog";

class NewLoanerBadge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            enableBadgeReturn: false,
            activeUser: this.props.activeUser,

            readOnly: this.props.readOnly,

            loadingActive: true,

            data: {
                requestNumber: 0,
                date: new Date(),
                employeeId: "",
                employeeName: "",
                badgeNumber: { cardId: "", cardName: "" },
                badgeReturned: false,
            },

            selectedEmployee: { emp_Code: "", emp_Name: "" },
            employees: [],
            cards: [],

            loanerBadgeId: 0,
            updateLoanerBadge: false,

            errorDialogOpen: false,
            errorDialogMessage: "",
        };

        this.getEmployees = this.getEmployees.bind(this);
        this.getCards = this.getCards.bind(this);
        this.getLoanerBadge = this.getLoanerBadge.bind(this);
        this.getCardInfo = this.getCardInfo.bind(this);

        this.validate = this.validate.bind(this);
        this.validateLoanerBadge = this.validateLoanerBadge.bind(this);

        this.emptyOrLoadDataObject = this.emptyOrLoadDataObject.bind(this);

        ApiCallGetEmployees(this.props.authToken, this.getEmployees);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.open !== this.props.open ||
            prevProps.enableBadgeReturn !== this.props.enableBadgeReturn ||
            prevProps.readOnly !== this.props.readOnly
        ) {
            this.setState({
                dialogOpen: this.props.open,
                enableBadgeReturn: this.props.enableBadgeReturn,

                loanerBadgeId: this.props.loanerBadgeId,
                updateLoanerBadge: this.props.updateLoanerBadge,
                activeUser: this.props.activeUser,

                readOnly: this.props.readOnly,
            });

            if (this.props.open === true) {
                this.emptyOrLoadDataObject(
                    this.props.updateLoanerBadge,
                    this.props.loanerBadgeId
                );
            }
        }
    }

    getReadOnly(component) {
        return GetPer(
            this.props.activeUser,
            this.props.readOnly,
            component,
            "loaners"
        );
    }

    getEmployees(invalid, data) {
        let firstEmployee = data[0];
        let d = this.state.data;

        d.employeeName = firstEmployee.emp_Name;
        d.employeeId = firstEmployee.emp_Code;

        this.setState({
            employees: data,
            selectedEmployee: firstEmployee,
            data: d,
        });

        ApiCallGetCards(this.props.authToken, false, this.getCards);
    }

    getCards(invalid, data) {
        this.setState({
            loadingActive: false,
            cards: data,
        });
    }

    getCardInfo(badgeNumber) {
        for (let c of this.state.cards) {
            if (c.cardId === badgeNumber) {
                return c;
            }
        }

        return this.state.cards[0];
    }

    emptyOrLoadDataObject(update, badgeId) {
        if (update === false) {
            this.setState({
                data: {
                    requestNumber: 0,
                    date: new Date(),
                    employeeId: "",
                    employeeName: "",
                    badgeNumber: this.getCardInfo(""),
                    badgeReturned: false,
                },

                selectedEmployee: { emp_Code: "", emp_Name: "" },
            });
        } else {
            this.setState({ loadingActive: true });
            ApiCallGetLoanerBadge(
                this.props.authToken,
                badgeId,
                this.getLoanerBadge
            );
        }
    }

    getLoanerBadge(invalid, data) {
        // requestNumber: 11
        // date: "2020-03-27T00:00:00"
        // employeeId: "0303"
        // employeeName: "Stevens, Laurie K."
        // badgeNumber: "234"
        // badgeReturned: false

        data.badgeNumber = this.getCardInfo(data.badgeNumber.toString());

        this.setState({
            data: data,
            selectedEmployee: {
                emp_Code: data.employeeId,
                emp_Name: data.employeeName,
            },

            loadingActive: false,
        });
    }

    validate() {
        let data = JSON.parse(JSON.stringify(this.state.data));
        data.badgeNumber = data.badgeNumber.cardId;

        this.setState({ loadingActive: true });
        ApiCallValidateLoanerBadge(
            this.props.authToken,
            data,
            this.validateLoanerBadge
        );
    }

    validateLoanerBadge(invalid, data) {
        this.setState({ loadingActive: false });

        if (data.validationOk === false) {
            this.setState({
                errorDialogOpen: true,
                errorDialogMessage: data.validationMessage,
            });
        } else {
            this.setState({ dialogOpen: false });
            this.props.closeHandler(
                true,
                this.state.updateLoanerBadge,
                this.state.data
            );
        }
    }

    render() {
        return (
            <>
                <AlertDialog
                    title="Error"
                    message={this.state.errorDialogMessage}
                    open={this.state.errorDialogOpen}
                    closeHandler={() => {
                        this.setState({ errorDialogOpen: false });
                    }}
                />

                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={this.state.dialogOpen}
                >
                    <DialogTitle>Loaners</DialogTitle>

                    <LoadingOverlay
                        active={this.state.loadingActive}
                        spinner
                        text="Please wait..."
                    >
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="standard"
                                        margin="dense"
                                        fullWidth
                                        label="Request Number"
                                        size="small"
                                        disabled
                                        value={
                                            this.state.updateLoanerBadge ===
                                                false ||
                                            this.state.data.requestNumber ===
                                                undefined
                                                ? " "
                                                : this.state.data.requestNumber.toString()
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            readOnly={
                                                this.state.readOnly === true ||
                                                this.getReadOnly("loanerdate")
                                            }
                                            inputProps={{
                                                readOnly:
                                                    this.state.readOnly ===
                                                        true ||
                                                    this.getReadOnly(
                                                        "loanerdate"
                                                    ),
                                            }}
                                            margin="dense"
                                            label="Date"
                                            format="MM/dd/yyyy"
                                            value={this.state.data.date}
                                            onChange={(value) => {
                                                let data = this.state.data;
                                                data.date = value;
                                                this.setState({ data: data });
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <Grid item xs={6}></Grid>

                                <Grid item xs={6}>
                                    {/* <TextField variant="standard" margin="dense" fullWidth label="Employee Id" size="small" value={this.state.data.employeeId} onChange={(e) => { let data = this.state.data; data.employeeId = e.target.value; this.setState({ data: data }) }} /> */}

                                    <Autocomplete
                                        disableClearable
                                        value={this.state.selectedEmployee}
                                        onChange={(event, value) => {
                                            let data = this.state.data;

                                            data.employeeId = value.emp_Code;
                                            data.employeeName = value.emp_Name;

                                            this.setState({
                                                data: data,
                                                selectedEmployee: value,
                                            });
                                        }}
                                        autoHighlight
                                        options={
                                            this.state.readOnly === true ||
                                            this.getReadOnly("loaneremployee")
                                                ? [this.state.selectedEmployee]
                                                : this.state.employees
                                        }
                                        getOptionLabel={(option) =>
                                            option.emp_Code +
                                            " " +
                                            option.emp_Name
                                        }
                                        key="autocompleteemployees-key"
                                        renderOption={(option) => (
                                            <Typography
                                                variant="body2"
                                                component="span"
                                            >
                                                {option.emp_Code +
                                                    " " +
                                                    option.emp_Name}
                                            </Typography>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                key="textfieldemployee-key"
                                                size="small"
                                                margin="dense"
                                                fullWidth
                                                {...params}
                                                label="Employee Id"
                                                variant="standard"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete:
                                                        "new-password", // disable autocomplete and autofill

                                                    readOnly:
                                                        this.state.readOnly ===
                                                            true ||
                                                        this.getReadOnly(
                                                            "loaneremployee"
                                                        ),
                                                }}
                                            />
                                        )}
                                    ></Autocomplete>
                                </Grid>
                                <Grid item xs={6}></Grid>

                                <Grid item xs={12}>
                                    <Box margin={3}></Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Autocomplete
                                        disableClearable
                                        value={this.state.data.badgeNumber}
                                        onChange={(event, value) => {
                                            let data = this.state.data;

                                            data.badgeNumber = value;

                                            this.setState({ data: data });
                                        }}
                                        autoHighlight
                                        options={
                                            this.state.readOnly === true ||
                                            this.getReadOnly("loanerbadge")
                                                ? [this.state.data.badgeNumber]
                                                : this.state.cards
                                        }
                                        getOptionLabel={(option) =>
                                            option.cardName === ""
                                                ? " "
                                                : option.cardName
                                        }
                                        key="autocompletecards-key"
                                        renderOption={(option) => (
                                            <Typography
                                                variant="body2"
                                                component="span"
                                            >
                                                {option.cardName === ""
                                                    ? "(none)"
                                                    : option.cardName}
                                            </Typography>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                key="textfieldcard-key"
                                                size="small"
                                                margin="dense"
                                                fullWidth
                                                {...params}
                                                label="Badge Number"
                                                variant="standard"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete:
                                                        "new-password", // disable autocomplete and autofill

                                                    readOnly:
                                                        this.state.readOnly ===
                                                            true ||
                                                        this.getReadOnly(
                                                            "loanerbadge"
                                                        ),
                                                }}
                                            />
                                        )}
                                    ></Autocomplete>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box margin={3}></Box>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={
                                                    this.state
                                                        .enableBadgeReturn ===
                                                    false
                                                }
                                                size="medium"
                                                checked={
                                                    this.state.data
                                                        .badgeReturned
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        this.state.readOnly ===
                                                            true ||
                                                        this.getReadOnly(
                                                            "loanerbadgereturned"
                                                        )
                                                    )
                                                        return;
                                                    let data = this.state.data;
                                                    data.badgeReturned =
                                                        e.target.checked;
                                                    this.setState({
                                                        data: data,
                                                    });
                                                }}
                                            />
                                        }
                                        label="Badge Returned"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.setState({ dialogOpen: false });
                                    this.props.closeHandler(false);
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                disabled={
                                    this.state.readOnly === true ||
                                    this.getReadOnly("ok")
                                }
                                color="primary"
                                onClick={this.validate}
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </LoadingOverlay>
                </Dialog>
            </>
        );
    }
}

export default NewLoanerBadge;
