export function GetPer(activeUser, readOnly, component, page) {
    if (activeUser && activeUser.persona) {
        let persona = activeUser.persona;

        if (page === "guests") {
            // check for Employee
            if (persona !== 1) {
                // Employee can change only number of visitors, but not guest badges
                if (readOnly === false) {
                    if (component === "numofguests") return false;
                    if (component === "guestname") return true;
                    if (component === "guestbadge") return true;
                    if (component === "guestbadgereturned") return true;
                    if (component === "ok") return false;
                }
            }

            // check for Admin
            if (persona === 1) {
                // if we are on "archive" page - admin can unarchive request by setting badge returned to false
                // but that is only that is available to set on archive page
                if (readOnly === true) {
                    if (component === "numofguests") return true;
                    if (component === "guestname") return true;
                    if (component === "guestbadge") return true;
                    if (component === "guestbadgereturned") return false;
                    if (component === "ok") return false;
                } else {
                    // if we are NOT on archive page
                    // enable all for admin
                    return false;
                }
            }
        }

        if (page === "loaners") {
            if (persona === 4) {
                // IT team cannot change loaner badges
                return true;
            }

            if (persona === 3) {
                // Front Desk team can only set loaner badge returned
                if (readOnly === false) {
                    if (component === "ok") return false;
                    if (component === "loanerbadgereturned") return false;
                    if (component === "loanerbadge") return true;
                    if (component === "loaneremployee") return true;
                    if (component === "loanerdate") return true;
                }
            }

            if (persona === 2) {
                // Employees can create new loaner badges but not set them to returned
                if (readOnly === false) {
                    if (component === "ok") return false;
                    if (component === "loanerbadgereturned") return true;
                    if (component === "loanerbadge") return false;
                    if (component === "loaneremployee") return false;
                    if (component === "loanerdate") return false;
                }
            }

            if (persona === 1) {
                // Admin has full privileged
                if (readOnly === false) {
                    return false;
                }
            }
        }

        if (page === "request") {
            if (persona === 1) {
                // Admin has full privileges
                if (readOnly === false) {
                    return false;
                }
            }

            // if (persona === 4) {
            //     // IT team can change only wifi and teleconference info
            //     if (readOnly === false) {
            //         if (component === "wifi") return false;
            //         if (component === "wifidetails") return false;
            //         if (component === "teleconference") return false;
            //         if (component === "teledetails") return false;
            //         if (component === "notes") return false;
            //         if (component === "ok") return false;

            //         // disable everything else
            //         return true;
            //     }
            // }
        }

        if (page === "main") {
            if (persona === 1) {
                // Admin has full privileges
                return false;
            }

            // Employees cannot issue loaner badges
            if (component === "newvisitorrequest") return false;
            if (component === "newloanerrequest") return true;
        }
    }

    if (readOnly === true) return true;

    return false;
}
