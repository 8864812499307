import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import React, { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "../ADLogin/AuthProvider";
import theme from "../theme";
import Header from "./Header";
import Login from "./Login";
import MainForm from "./MainForm";
import Users from "./Users";

function Application(props: any): ReactElement {
  const getActiveWindow = () => {
    if (!props.account || !props.token) {
      return <Login onLoginClick={props.onSignIn} />;
    } else {
      return (
        <Routes>
          <Route
            path="/"
            element={
              <MainForm
                key="active"
                authToken={props.token}
                module="activerequests"
                moduleTitle="Active Requests"
              />
            }
          />
          <Route
            path="/archive"
            element={
              <MainForm
                key="archived"
                authToken={props.token}
                module="archivedrequests"
                moduleTitle="Archived Requests"
              />
            }
          />
          <Route
            path="/users"
            element={<Users key="users" authToken={props.token} />}
          />
        </Routes>
      );
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Header isSignedIn={props.account} onLogoutClick={props.onSignOut} />
        {getActiveWindow()}
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default AuthProvider(Application);
